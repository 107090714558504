import * as R from "ramda";

export function getSubdomain(hostname) {
  const regexParse = new RegExp("[a-z-0-9]{2,63}.[a-z.]{2,5}$");
  const urlParts = regexParse.exec(hostname);
  // || hostname is for local dev, to enable changing server in local dev mode
  //console.log('foooooo', hostname)
  if (hostname === "192.168.86.33") return process.env.REACT_APP_DEFAULT_CUSTOMER;
  const subdomain = hostname.replace(urlParts[0], "").slice(0, -1) || hostname;
  // Remove -staging and -dev from subdomain name.
  const subDomainWithoutEnv = R.replace("-dev", "", R.replace("-staging", "", R.replace("-demo", "", R.replace("moi2-", "", subdomain))));
  // console.log(subDomainWithoutEnv , subdomain)
  if (subDomainWithoutEnv === "localhost" || subDomainWithoutEnv === "bs-local.com" || subDomainWithoutEnv === "palko-stage") {
    return process.env.REACT_APP_DEFAULT_CUSTOMER;
  } else {
    return subDomainWithoutEnv;
  }
}

function getAnalyticsSubDomain(hostname) {
  const regexParse = new RegExp("[a-z-0-9]{2,63}.[a-z.]{2,5}$");
  const urlParts = regexParse.exec(hostname);
  // || hostname is for local dev, to enable changing server in local dev mode
  const subDomain = urlParts ? hostname.replace(urlParts[0], "").slice(0, -1) || hostname : hostname;
  // Remove -staging and -dev from subdomain name.
  if (subDomain === "localhost") {
    return process.env.REACT_APP_DEFAULT_ANALYTICS;
  } else {
    return subDomain;
  }
}

export function getAnalyticsConfig(hostname) {
  const subDomain = getAnalyticsSubDomain(hostname);
  switch (subDomain) {
    case "hamina-dev":
      return "2";
    case "carea-dev":
      return "3";
    case "ptoolonkatu-dev":
      return "4";
    default:
      return null;
  }
}

export function getLangSettings(data) {
  const items = R.path(["customer", "lang"], data);
  const lang = R.find(R.propEq("default", 1))(R.values(items));
  const defaultLang = R.defaultTo("", R.prop("code", lang));
  return { defaultLang, items };
}
